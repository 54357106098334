import React, { useState, useEffect } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { richText } from '../../util/richText';
import { capitalize } from 'lodash';
import { NamedLink } from '../../components';
import css from './ListingPage.module.css';
import { Modal, Button } from '../../components'; // Import necessary components

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionDescriptionMaybe = props => {
  const {
    authorDisplayName,
    authorID,
    slug,
    description,
    listingTitle,
    partnericonw,
    verified,
    listing,
    currentUser,
    isAuthenticated,
    onManageDisableScrolling,
    onUpdateCurrentUserProfile,
  } = props;

  // State for favModal and icons
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [favouriteCSS, setFavouriteCSS] = useState(css.heartIcon);

  useEffect(() => {
    setMounted(true);
  }, []);

  const id = listing?.id?.uuid;
  const authenticatedOnClientSide = mounted && isAuthenticated;

  useEffect(() => {
    if (authenticatedOnClientSide) {
      const favourites = currentUser?.attributes?.profile?.protectedData?.favouriteListings;
      if (favourites) {
        setFavouriteCSS(favourites.includes(id) ? css.heartIconLiked : css.heartIcon);
      }
    }
  }, [authenticatedOnClientSide, currentUser, id]);

  const addListingToCurrentUserFavourites = (e) => {
    e.preventDefault();

    if (authenticatedOnClientSide) {
      let newFavourites = [...(currentUser?.attributes?.profile?.protectedData?.favouriteListings || [])];
      let protectedData = currentUser.attributes.profile.protectedData;

      if (!newFavourites.includes(id)) {
        newFavourites = [...newFavourites, id];
        protectedData = { ...protectedData, favouriteListings: newFavourites };
      } else {
        newFavourites = newFavourites.filter(f => f !== id);
        protectedData = { ...protectedData, favouriteListings: newFavourites };
      }

      onUpdateCurrentUserProfile({ protectedData });
      setIsModalOpen(false);
    } else {
      setIsModalOpen(true);
    }
  };

  const favModal = (
    onManageDisableScrolling ? (
      <Modal
        id="authenticateUserPopup"
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        usePortal
        contentClassName={css.modalContent}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <h2 className={css.modalTitle}>
          <FormattedMessage id="AuthenticationModal.title" />
        </h2>
        <div className={css.modalDescription}>
          <p>
            <FormattedMessage id="AuthenticationModal.Description" />
          </p>
          <Button onClick={() => {
            setIsModalOpen(false);
            window.location.href = "/login";
          }}>
            <FormattedMessage id="AuthenticationModal.Button" />
          </Button>
        </div>
      </Modal>
    ) : null
  );

  return description ? (
    <div className={css.sectionDescription}>
      <NamedLink className={css.authorNameLink} name="ProfilePage" params={{ id: authorDisplayName, slug }}>
        <h3 className={`${css.authorName}`}>
          {authorDisplayName.toUpperCase()}
          {verified ?
            <img className={css.verifiedBarge} src={partnericonw} alt="verified_barged" height={8} />
            : null}
        </h3>
      </NamedLink>

      <p className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.descriptionTitle" values={{ listingTitle }} />
      </p>
      <p className={css.description}>
        {richText(description, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
          longWordClass: css.longWord,
        })}
      </p>
      {favModal}
      <div className={css.listingLikeIconMobile}>
        <svg
          className={favouriteCSS}
          onClick={addListingToCurrentUserFavourites}
          xmlns="http://www.w3.org/2000/svg"
          width="24" height="24" viewBox="0 0 24 24"
          fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>
        </svg>
      </div>
    </div>
  ) : null;
};

export default SectionDescriptionMaybe;