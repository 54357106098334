import React, { useEffect, useState } from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

import config from '../../../config';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { propTypes } from '../../../util/types';
import { types as sdkTypes } from '../../../util/sdkLoader';
import { Form, PrimaryButton, FieldTextInput, FieldCheckbox, IconEnquiry } from '../../../components';
import { formatMoney } from '../../../util/currency';

import css from './EnquiryForm.module.css';

const EnquiryFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        submitButtonWrapperClassName,
        formId,
        handleSubmit,
        inProgress,
        intl,
        listingTitle,
        listingImage,
        listingSize,
        listingPrice,
        authorDisplayName,
        offerClicked,
        sendEnquiryError,
        changeOfferValue,
      } = fieldRenderProps;

      const { Money } = sdkTypes;

      const [customErrorText, setCustomErrorText] = useState(null);
      const [customOfferValue, setCustomOfferValue] = useState('');

      const authorUserName = authorDisplayName?.toUpperCase();

      let tempPrice = {...listingPrice};

      const getNewOfferPrice = (offerPercentage) => {
        tempPrice.amount = (listingPrice.amount * offerPercentage);
        return new Money(tempPrice.amount, config.currency);
      };

      const validateOffer = (value) => {
        const customOffer = value * 100;
        if (customOffer < listingPrice.amount * 0.5) {
          setCustomErrorText(intl.formatMessage({ id: 'EnquiryForm.offerAmountTooLow' }));
          return false;
        } else if (customOffer > listingPrice.amount * 1.5) {
          setCustomErrorText(intl.formatMessage({ id: 'EnquiryForm.offerAmountTooHigh' }));
          return false;
        } else {
          setCustomErrorText(null);
          return true;
        }
      };

      const updateCustomOffer = (e) => {
        validateOffer(e.target.value)? (
          setCustomOfferValue(formatMoney(intl,  new Money(e.target.value * 100, config.currency)))
        ) : (setCustomOfferValue(''));

        const custOffer = document.getElementById('customOffer');
        custOffer.checked? (changeOfferValue(customOfferValue)) : null;
      }

      const offerPlaceholder = intl.formatMessage(
        {
          id: 'EnquiryForm.offerPlaceholder',
        }
      );

      const offerRequiredMessage = intl.formatMessage({
        id: 'EnquiryForm.offerRequired',
      });
      const offerRequired = validators.requiredFieldArrayCheckbox(offerRequiredMessage);

      const messageLabel = intl.formatMessage(
        {
          id: 'EnquiryForm.messageLabel',
        },
        { authorDisplayName: authorUserName }
      );

      const messagePlaceholder = intl.formatMessage(
        {
          id: 'EnquiryForm.messagePlaceholder',
        },
        { authorDisplayName: authorUserName }
      );

      const messageRequiredMessage = intl.formatMessage({
        id: 'EnquiryForm.messageRequired',
      });
      const messageRequired = validators.requiredAndNonEmptyString(messageRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = submitInProgress;

      const onlyOneChecked = (e) => {
        const offer = document.getElementsByName('offer');

        offer.forEach((item) => {
          (item != e.target)? item.checked = false 
          : changeOfferValue(item.value)
        });
      };

      return (
        <Form className={classes} onSubmit={handleSubmit} enforcePagePreloadFor="OrderDetailsPage">
          <div className={css.formHeadingDivision}>
            <div className={css.formDetails}>
              <h3 className={css.heading}>
                {!offerClicked? 
                  (<FormattedMessage id="EnquiryForm.heading" />) :
                  (<FormattedMessage id="EnquiryForm.offerHeading" />)
                }                
              </h3>
              <span className={css.listingAuthor}>
                {authorUserName}
              </span>
              <span className={css.listingTitle}>
                {listingTitle}
              </span>
              <span className={css.listingSize}>
                {"SIZE: " + listingSize}
              </span>
              <span className={css.listingPrice}>
                {formatMoney(intl, listingPrice) + " USD"}
              </span>
            </div>
            <div className={css.formImage}>
              <img 
                className={css.listingImage}
                src={listingImage?.attributes?.variants?.embryoStyle?.url}
                alt="listing image"
                height={150}
                width={100}
              />
            </div>
          </div>
          { !offerClicked? (
            <div className={css.messageForm}>
          <FieldTextInput
            className={css.field}
            type="textarea"
            name="message"
            id={formId ? `${formId}.message` : 'message'}
            placeholder={messagePlaceholder}
            validate={messageRequired}
          />
          </div>) : (
            <div className={css.offerForm}>

              <FieldCheckbox
                id={formatMoney(intl, getNewOfferPrice(0.9))}
                className={css.tenPercentOff}
                name="offer"
                label={formatMoney(intl, getNewOfferPrice(0.9)) + " USD (10% OFF)"}
                value={formatMoney(intl, getNewOfferPrice(0.9))}
                onChange={onlyOneChecked.bind(this)}
                validate={offerRequired}
              />
              <FieldCheckbox
                id={formatMoney(intl, getNewOfferPrice(0.8))}
                className={css.twentyPercentOff}
                name="offer"
                label={formatMoney(intl, getNewOfferPrice(0.8)) + " USD (20% OFF)"}
                value={formatMoney(intl, getNewOfferPrice(0.8))}
                onChange={onlyOneChecked.bind(this)}
                validate={offerRequired}
              />
              <FieldCheckbox
                id={formatMoney(intl, getNewOfferPrice(0.7))}
                className={css.thirtyPercentOff}
                name="offer"
                label={formatMoney(intl, getNewOfferPrice(0.7)) + " USD (30% OFF)"}
                value={formatMoney(intl, getNewOfferPrice(0.7))}
                onChange={onlyOneChecked.bind(this)}
                validate={offerRequired}
              />
              <FieldCheckbox
                id="customOffer"
                className={css.customOffer}
                name="offer"
                label={"ENTER CUSTOM OFFER "}
                value={customOfferValue}
                onChange={onlyOneChecked.bind(this)}
                validate={offerRequired}
              />
              <span className={css.customOfferTextInput}>
                <p className={css.customOfferError}>{customErrorText}</p>
                <input 
                  id="customOfferText" 
                  className={`${css.customOfferText} ${customErrorText? css.customOfferTextError : null}`}
                  name="offerText"
                  placeholder={offerPlaceholder}
                  type="number"
                  onChange={updateCustomOffer}
                />
              </span>
            </div>
            )}
          <div className={submitButtonWrapperClassName}>
            <p className= {css.respect}>
            {!offerClicked? (
                <FormattedMessage id="EnquiryForm.EnquiryWarning"/>
              ) : null}            
            </p>
            {sendEnquiryError ? (
              <p className={css.error}>
                <FormattedMessage id="EnquiryForm.sendEnquiryError" />
              </p>
            ) : null}
            <PrimaryButton 
              type="submit"
              inProgress={submitInProgress} 
              disabled={submitDisabled}
            >
              <FormattedMessage id="EnquiryForm.submitButtonText" />
            </PrimaryButton>
            {offerClicked? (
              <p className= {css.offerTip}>
              <FormattedMessage id="EnquiryForm.offerTip"/>
            </p>
            ) : null}  
          </div>
        </Form>
      );
    }}
  />
);

EnquiryFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendEnquiryError: null,
};

EnquiryFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  listingTitle: string.isRequired,
  authorDisplayName: string.isRequired,
  sendEnquiryError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const EnquiryForm = compose(injectIntl)(EnquiryFormComponent);

EnquiryForm.displayName = 'EnquiryForm';

export default EnquiryForm;
